<template>
  <v-card height="100%" tile flat>
    <v-navigation-drawer permanent>
      <v-list>
        <v-list-item :inactive="true">
          <v-list-item-content>
            <v-list-item-subtitle class="title"> Profile </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ getUser.userName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ getUser.email }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-btn x-small @click="navigate('ChangePassword')"
                >Change Password</v-btn
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list v-for="(item, i) in parcel" :key="i">
        <v-list-item
          :class="$route.name === item.navigate ? 'highlighted' : 'pointer'"
          :inactive="true"
          @click="navigate(item.navigate)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
      <div class="text-center mt-10">
        <v-btn @click="logout" rounded dark> Logout </v-btn>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  components: {},
  data: () => ({
    selectedItem: 0,
    keyword: "",
    dialog: false,
    parcel: [
      {
        text: "All Users",
        icon: "mdi-file-chart-outline",
        navigate: "allUsers",
      },
      {
        text: "Investment",
        icon: "mdi-file-chart-outline",
        navigate: "Investment",
      },
      {
        text: "Investment Plan",
        icon: "mdi-file-chart-outline",
        navigate: "InvestmentPlan",
      },
      {
        text: "Withdrawal",
        icon: "mdi-file-chart-outline",
        navigate: "Withdrawal",
      },
      {
        text: "Admin",
        icon: "mdi-file-chart-outline",
        navigate: "Admin",
      },
      {
        text: "Messages",
        icon: "mdi-file-chart-outline",
        navigate: "Messages",
      },
      {
        text: "News Letter Sub.",
        icon: "mdi-file-chart-outline",
        navigate: "newsLetter",
      },
    ],
  }),
  created() {},
  methods: {
    logout() {
      let payload = {
        route: this.$router,
      };
      this.$store.commit("setSignOut", payload);
    },
    navigate(payload) {
      this.$router.push({ name: payload });
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>
  
  <style scoped>
.pointer :hover {
  cursor: pointer;
  background-color: #40abf7 !important;
  color: #fff;
}
.highlighted {
  background-color: #40abf7 !important;
  color: #fff;
}
</style>