<template>
  <div class="main">
    <v-row no-gutters justify="center">
      <v-col class="ma-5 sideNav" cols="12" md="2" sm="3">
        <SideBar />
      </v-col>
      <v-col class="mainDiv" cols="12" md="9" sm="8">
        <v-toolbar flat>
          <v-toolbar-title>
            Dashboard/<span class="pageName">{{ page }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
import SideBar from "../components/SideNav";

export default {
  data() {
    return {};
  },
  components: {
    SideBar,
  },
  computed: {
    page() {
      return this.$route.name;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
};
</script>
  
<style scoped>
.main {
  height: 90vh;
  overflow: hidden;
}
.mainDiv {
  height: 100vh;
  overflow: hidden;
}
.view-enter-active,
.view-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}
.view-enter-active {
  transition-delay: 0.5s;
}
.view-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.view-enter-to {
  opacity: 1;
  transform: translateX(0px);
}
.view-leave {
  opacity: 1;
  transform: translateX(0px);
}
.view-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.centerCard {
  width: 450px;
}
mainDiv::-webkit-scrollbar {
  width: 0.4em !important;
}
mainDiv::-webkit-scrollbar-thumb {
  background-color: #193471 !important;
  border-radius: 10px !important;
}
@media only screen and (max-width: 800px) {
  .sideNav {
    display: none;
  }
}
</style>